import useScopedClassName from "@/hooks/useScopedClassName";
import { getPagesByPageTypeChunked } from "@/services/pageService/pageService";
import { useAppSelector } from "@/store/store";
import { ContentElementSliderStoreSetting } from "@/types/ceSettings/ceSettings";
import { CESlider } from "@/types/content-elements";
import clsx from "clsx";
import React from "react";
import { Col, Row } from "react-bootstrap";
import ContentElementWrapper from "../wrapper/contentElementWrapper";
import CmsSliderContentElementDropdown from "./cmsSliderContentElementDropdown";
import SliderContentElementStyleGlobal from "./sliderContentElementStyleGlobal";
import SliderContentElementStyleScoped from "./sliderContentElementStyleScoped";
import SwiperComponent from "./swiperComponent/swiperComponent";

export interface SliderContentElementProps {
  content: CESlider;
  position: number;
  ceSettings?: ContentElementSliderStoreSetting;
  isLastElement: any;
  isMobile: boolean;
  isFirstElement: boolean;
}

export const SLIDER_CE_NAME = "sliderCE";
const CHUNK_SIZE_FALLBACK = 6;

const SliderContentElement = (props: SliderContentElementProps) => {
  const SLIDER_SCOPED_CLASS = useScopedClassName(
    SLIDER_CE_NAME,
    props.content,
    props.position
  );
  const editMode = useAppSelector((state) => state.cmsGeneral.editMode);

  const getCesBasedKey = () => {
    return `${props.content.cfgSelectPageTypeIds}-${props.content.cfgSelectOptionalCategoryIds}-${props.ceSettings?.id}-${props.position}`;
  };

  const maxSlideAmountNumber = props.ceSettings?.slidesPerView
    ? Math.floor(Math.max(...Object.values(props.ceSettings?.slidesPerView)))
    : CHUNK_SIZE_FALLBACK;
  // Make sure there's always more than the currently visible number fetched.
  const elementsPerFetch = maxSlideAmountNumber
    ? maxSlideAmountNumber + 1
    : CHUNK_SIZE_FALLBACK;

  return (
    <>
      <ContentElementWrapper
        ceSettings={props.ceSettings}
        scopedClassName={SLIDER_SCOPED_CLASS}
        name={SLIDER_CE_NAME}
        content={props.content}
        isFirstElement={props.isFirstElement}
        isLastElement={props.isLastElement}
        position={props.position}
      >
        <Row className={clsx(SLIDER_CE_NAME, SLIDER_SCOPED_CLASS, "g-0")}>
          <Col
            xs={12}
            style={{ height: "100%", alignContent: "center" }}
            key={getCesBasedKey()}
          >
            {props.content.cfgSelectPageTypeIds &&
            props.content.cfgSelectPageTypeIds.length > 0 ? (
              <SwiperComponent
                getMoreSlidesFunction={getPagesByPageTypeChunked}
                getMoreSlidesFunctionParams={{
                  pageTypeIds:
                    props.content.cfgSelectPageTypeIds.length > 0
                      ? props.content.cfgSelectPageTypeIds
                      : undefined,
                  categories: props.content.cfgSelectOptionalCategoryIds,
                  chunkSize: elementsPerFetch,
                }}
                ceSettings={props.ceSettings}
                layout="default"
                scopedClassName={SLIDER_SCOPED_CLASS}
                content={props.content}
              />
            ) : null}
            {(!props.content.cfgSelectPageTypeIds ||
              props.content.cfgSelectPageTypeIds.length === 0) &&
            editMode ? (
              <CmsSliderContentElementDropdown position={props.position} />
            ) : null}
          </Col>
        </Row>
      </ContentElementWrapper>
      <SliderContentElementStyleGlobal {...props} />
      <SliderContentElementStyleScoped
        {...props}
        scopedSelector={SLIDER_SCOPED_CLASS}
      />
    </>
  );
};

export default React.memo(SliderContentElement);
