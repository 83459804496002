import useStrColor from "@/hooks/useStrColor";
import {
  asPx,
  lowerBreakpointDesktopPx,
  lowerBreakpointTabletPx,
  lowerBreakpointWqhdPx,
} from "@/utils/util";
import { SliderContentElementProps } from "./sliderContentElement";

interface SliderContentElementStyleScopedProps
  extends SliderContentElementProps {
  scopedSelector: string;
}

/**
 * This is the scoped style from a single CE instance
 * Here you can explicitly style one CE
 *
 * @param props
 * @returns
 */
export default function SliderContentElementStyleScoped(
  props: SliderContentElementStyleScopedProps
) {
  const swiperSlideHeightMobile = props.ceSettings?.height?.mobile ?? 300;
  const swiperSlideHeightTablet = props.ceSettings?.height?.tablet ?? 400;
  const swiperSlideHeightDesktop = props.ceSettings?.height?.desktop ?? 500;
  const swiperSlideHeightWQHD = props.ceSettings?.height?.wqhd ?? 600;

  const swiperHeightMobile = swiperSlideHeightMobile + 8;
  const swiperHeightTablet = swiperSlideHeightTablet + 10;
  const swiperHeightDesktop = swiperSlideHeightDesktop + 12;
  const swiperHeightWQHD = swiperSlideHeightWQHD + 14;

  const imageHeightMobile = props.ceSettings?.imageHeight?.mobile ?? 175;
  const imageHeightTablet = props.ceSettings?.imageHeight?.tablet ?? 230;
  const imageHeightDesktop = props.ceSettings?.imageHeight?.desktop ?? 290;
  const imageHeightWQHD = props.ceSettings?.imageHeight?.wqhd ?? 350;

  const slideBorder = props.ceSettings?.slideBorder ?? "border: unset;";
  const { colorCssVar: slideBgColor } = useStrColor(
    props.content.cfgStrBackgroundColorSlide,
    props.ceSettings?.bgColorsSlides,
    "var(--pb-white)"
  );

  const { colorCssVar: paginationActiveBulletColor } = useStrColor(
    props.ceSettings?.paginationActiveBulletColor,
    undefined,
    "var(--pb-cms-color)"
  );

  const { colorCssVar: paginationInactiveBulletColor } = useStrColor(
    props.ceSettings?.paginationInactiveBulletColor,
    undefined,
    "var(--pb-black)"
  );

  const spacerBelowTeaser = props.ceSettings?.spacerBelowTeaser ?? 5;
  const spacerBelowTitle = props.ceSettings?.spacerBelowTitle ?? 10;

  return (
    <style jsx global>{`
      :global(.${props.scopedSelector}) {
        height: ${asPx(swiperHeightMobile)};
        @media (min-width: ${lowerBreakpointTabletPx()}) {
          height: ${asPx(swiperHeightTablet)};
        }

        @media (min-width: ${lowerBreakpointDesktopPx()}) {
          height: ${asPx(swiperHeightDesktop)};
        }
        @media (min-width: ${lowerBreakpointWqhdPx()}) {
          height: ${asPx(swiperHeightWQHD)};
        }

        --swiper-pagination-color: ${paginationActiveBulletColor};
        --swiper-pagination-bullet-inactive-color: ${paginationInactiveBulletColor};
        --swiper-pagination-bullet-size: 10px;
        .swiper-slide {
          height: ${asPx(swiperSlideHeightMobile)};
          ${slideBorder}
          ${!props.ceSettings?.showButton ? `cursor: pointer;` : ""}
          background-color: ${slideBgColor};

          .default-slide-layout .slide-button {
            background-color: ${slideBgColor};
            display: flex;
            justify-content: ${props.content.cfgCardButtonAlignment
              ? props.content.cfgCardButtonAlignment
              : "center"};
          }

          .default-slide-layout .slide-content {
            .teaser {
              margin-bottom: ${asPx(spacerBelowTeaser)} !important;
            }
            .title {
              margin-bottom: ${asPx(spacerBelowTitle)} !important;
            }
          }
          img.cms-image,
          .slide-image {
            height: ${asPx(imageHeightMobile)} !important;
            @media (min-width: ${lowerBreakpointTabletPx()}) {
              height: ${asPx(imageHeightTablet)} !important;
            }

            @media (min-width: ${lowerBreakpointDesktopPx()}) {
              height: ${asPx(imageHeightDesktop)} !important;
            }
            @media (min-width: ${lowerBreakpointWqhdPx()}) {
              height: ${asPx(imageHeightWQHD)} !important;
            }
          }

          @media (min-width: ${lowerBreakpointTabletPx()}) {
            height: ${asPx(swiperSlideHeightTablet)};
          }

          @media (min-width: ${lowerBreakpointDesktopPx()}) {
            height: ${asPx(swiperSlideHeightDesktop)};
          }
          @media (min-width: ${lowerBreakpointWqhdPx()}) {
            height: ${asPx(swiperSlideHeightWQHD)};
          }
        }
      }
    `}</style>
  );
}
